import '@babel/polyfill'; // Required for build
import load from './loader'; // Required for loading files dynamically
import 'bootstrap/js/dist/util'; // All Bootstrap JS requires Util
import 'bootstrap/js/dist/collapse'; // Used for nav collapse functionality
import 'bootstrap/js/dist/modal'; // Used for nav collapse functionality
import '@fortawesome/fontawesome';
import '@fortawesome/fontawesome-pro-solid';
import '@fortawesome/fontawesome-pro-regular';
import '@fortawesome/fontawesome-pro-light';
import '@fortawesome/fontawesome-free-brands';

import common from './common';
/*
    Basic Story Routing
    -------------------
    Switch on body class that gets applied either due to page, post type or page template
    Replace '-' in the class name with '_'
*/
const route = className => {
    switch (className) {

        case 'single_post':
            load('./post');
            break;
        case 'template_lodge_form':
            load('./lodge-form');
            break;

    }
};

// Fire events once document has loaded
document.addEventListener('DOMContentLoaded', () => {
    common(); // Run common code on every page

    const bodyClasses = document.body.className // Get all body classes,
        .replace(/-/g, '_') // replace dashes with underscores,
        .replace(/page_template_/g, '') // remove prefix 'page_template' from template classes, &
        .split(/\s+/); // split on whitespace

    for (const value of bodyClasses) {
        route(value); // Run javascript for each body class
    }
});
