import Lottie from 'lottie-web';
import Swiper from 'swiper';

const common = () => {
    $('.site-header button.hamburger').on('click', function() {
        $('.mobile-navigation').addClass('active');
    });

    $('.mobile-navigation button.hamburger-close').on('click', function() {
        $('.mobile-navigation').removeClass('active');
    });

    $('.featured-wrapper a, .article-item a').hover(
        function() {
            const svg = $(this).find('.arrow-hover .arrow');
            svg.attr('data-prefix', 'fas');
        }, // over
        function() {
            const svg = $(this).find('.arrow-hover .arrow');
            svg.attr('data-prefix', 'fal');
        }  // out
    );

    if ($('.hero-swiper').length) {
        new Swiper('.hero-swiper', {
            loop: true,
            a11y: true,
            keyboardControl: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.slider-next',
                prevEl: '.slider-prev',
            },
        });
    }

    Lottie.loadAnimation({
        container: document.getElementById('star-animation'),
        path: `${window.location.origin}/wp-content/themes/tccl/assets/json/data.json`,
        renderer: 'svg', // required
        loop: true, // optional
        autoplay: true, // optional
        name: 'Star Animation', // optional
    });

    Lottie.loadAnimation({
        container: document.getElementById('mobile-star-animation'),
        path: `${window.location.origin}/wp-content/themes/tccl/assets/json/data.json`,
        renderer: 'svg', // required
        loop: true, // optional
        autoplay: true, // optional
        name: 'Star Animation', // optional
    });
};


export default common;
