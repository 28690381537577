// Checks if parameter is of type Function, then runs if true
const checkThenRunFunction = func => {
    if (typeof func === typeof Function) {
        func();
    }
};

// Dynamically imports files when called
const load = (path, onlyDefault = false) => {
    // Just importing will run all plain javasctipt within the file
    import(/* webpackChunkName: "[request]" */ `${path}`).then(module => {
        if (!onlyDefault) {
            // Run all exported functions from file
            Object.keys(module).forEach(key => {
                checkThenRunFunction(module[key]);
            });
        } else {
            // Only run default export
            checkThenRunFunction(module.default);
        }
    });
};

export default load;
